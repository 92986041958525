/* global $ */

import "./scss/reset.scss";
import "./scss/common.scss";
import "./scss/navbar.scss";
import "./scss/biz-common.scss";

import md5 from "blueimp-md5";

function setupRem(baseFontSize = 100) {
    function changeAnchor(isMobile) {
        var arr = document.querySelectorAll(".mobile-anchor");
        arr.forEach(function(target) {
            if (isMobile) {
                if (!target.id) {
                    target.setAttribute(
                        "id",
                        target.getAttribute("data-anchor"),
                    );
                }
            } else {
                if (target.id) {
                    target.removeAttribute("id");
                }
            }
        });
    }
    function refreshRem() {
        var docEl = document.documentElement;
        var screenWidth = Math.min(
            screen.width,
            window.innerWidth,
            docEl.getBoundingClientRect().width,
        );
        var baseWidth = screenWidth > 1220 ? Math.min(screenWidth, 1920) : 1220;
        var rem =
            (screenWidth <= 428
                ? (screenWidth * baseFontSize) / 375
                : (Math.min(screenWidth, baseWidth) * baseFontSize) /
                  baseWidth) || baseFontSize;
        docEl.style.fontSize = rem + "px";

        var realitySize = parseInt(window.getComputedStyle(docEl).fontSize, 10);
        if (rem !== realitySize) {
            rem = (rem * rem) / realitySize;
            docEl.style.fontSize = rem + "px";
        }
        changeAnchor(screenWidth <= 428);
    }
    refreshRem();

    let tid;
    window.addEventListener(
        "resize",
        function() {
            clearTimeout(tid);
            tid = setTimeout(refreshRem, 300);
        },
        false,
    );
}

setupRem();

const $menuMask = $(".app-navbar-menu-mask");
const $menu = $(".app-navbar-menu").on("click", ".menuItem", e => {
    $(e.currentTarget)
        .toggleClass("active")
        .siblings(".menuItem")
        .removeClass("active");
});
$menu
    .find(".J_Close")
    .add($(".app-navbar-menu-mask"))
    .on("click", () => {
        $menu.anim(
            {
                transform: "translate(100%, 0%)",
            },
            0.2,
            "ease-out",
            () => {
                $menu.hide();
            },
        );
        $menuMask.anim(
            {
                opacity: 1,
            },
            0.2,
            "ease-out",
            () => {
                $menuMask.hide();
            },
        );
    });
$(".app-navbar-menu-icon").on("click", () => {
    $menuMask
        .css({
            opacity: 0,
        })
        .show()
        .anim(
            {
                opacity: 1,
            },
            0.2,
            "ease-out",
        );
    $menu
        .css({
            transform: "translate(100%, 0%)",
        })
        .show()
        .anim(
            {
                transform: "translate(0%, 0%)",
            },
            0.2,
            "ease-out",
        );
});

let isLangDropDownShow = false;
const bindHideLangDropDown = e => {
    if (!document.querySelector(".J_App_Lang").contains(e.target)) {
        hideLangDropDown();
        e.stopPropagation();
        e.preventDefault();
    }
};

const hideLangDropDown = () => {
    isLangDropDownShow = false;
    $(".J_App_Lang").hide();
    document.body.removeEventListener("click", bindHideLangDropDown, true);
};
$(".app-navbar-lang-icon").on("click", () => {
    if (!isLangDropDownShow) {
        isLangDropDownShow = true;
        $(".J_App_Lang").show();
        document.body.addEventListener("click", bindHideLangDropDown, true);
    }
});

// 处理nav导航交互--start
const menuBox = document.querySelector("#nav-business");
const menus = Array.from(menuBox.querySelectorAll(".subMenuItem"));
function judegSelect() {
    menus.forEach(i => {
        if (
            window.location.hash &&
            window.location.hash.includes(i.dataset.hash)
        ) {
            i.classList.add("selected");
        } else {
            i.classList.remove("selected");
        }
    });
}
window.addEventListener("hashchange", judegSelect);

const subMenus = Array.from(document.querySelectorAll(".subMenuItem"));
subMenus.forEach(i => {
    if (window.location.pathname === i.dataset.path) {
        i.classList.add("selected");
    }
});

judegSelect();

const langChangeZHCN = document.querySelector("#lang-change-zh-cn");
const langChangeZHTW = document.querySelector("#lang-change-zh-tw");
const langChangeENUS = document.querySelector("#lang-change-en-us");
function changeLang(dom, lang) {
    if (dom) {
        dom.addEventListener("click", () => {
            const { origin, pathname, hash, search } = window.location;
            const searchObj = {};
            if (search.length > 1) {
                const arr = search
                    .slice(1)
                    .split("&")
                    .filter(i => i);
                arr.forEach(i => {
                    const [key, value] = i.split("=");
                    searchObj[key] = value;
                });
            }
            searchObj.lang = lang === "ZH_CN" ? "" : lang;
            let searchStr = "?";
            let arr = Object.keys(searchObj).map(key => {
                if (!searchObj[key]) {
                    return null;
                }
                return `${key}=${searchObj[key]}`;
            });
            arr = arr.filter(item => !!item);
            if (!arr.length) {
                searchStr = "";
            }
            searchStr += arr.join("&");
            window.location.href = origin + pathname + searchStr + hash;
        });
    }
}
changeLang(langChangeZHCN, "ZH_CN");
changeLang(langChangeZHTW, "ZH_TW");
changeLang(langChangeENUS, "EN_US");

// 处理移动端回到顶部

function parseScrollToTop() {
    const dom = document.querySelector(".scroll-to-top");
    if (dom) {
        dom.addEventListener("click", () => {
            window.scrollTo(0, 0);
        });
        window.addEventListener("scroll", () => {
            if (window.scrollY > 500) {
                dom.classList.remove("scroll-to-top-hidden");
            } else {
                dom.classList.add("scroll-to-top-hidden");
            }
        });
    }
}

parseScrollToTop();

// --end

function ajax(url, method, data, beforeSend, success, error) {
    if (!url) return;
    var xhr = new XMLHttpRequest();
    xhr.addEventListener("load", function() {
        success(xhr.responseText, xhr);
    });
    error && xhr.addEventListener("error", error);

    xhr.open(method, url, true);
    xhr.setRequestHeader(
        "Content-Type",
        "application/x-www-form-urlencoded; charset=UTF-8",
    );

    beforeSend && beforeSend(xhr);

    xhr.send(data);
}

let apiHost = "api.jk.cn";
if (/\.dev\.|localhost/.test(location.host)) {
    apiHost = "api.dev.pajkdc.com";
} else if (/\.test\.pajk\.cn/.test(location.host)) {
    apiHost = "api.test.pajk.cn";
} else if (/\.test\./.test(location.host)) {
    apiHost = "api.test.pajkdc.com";
} else if (/\.pre\./.test(location.host)) {
    apiHost = "api.pre.jk.cn";
}

function gateway(params, success, error) {
    const tk = "jk.pingan.com";

    params = {
        ...params,
        _st: +new Date(),
        _sm: "md5",
    };

    var keys = [];
    for (var key in params) {
        keys.push(key);
    }
    keys.sort();
    var sig = "";
    var postString = "";
    for (var i = 0; i < keys.length; i++) {
        if (params[keys[i]] !== undefined && params[keys[i]] !== null) {
            sig += keys[i] + "=" + params[keys[i]];
            postString +=
                keys[i] + "=" + encodeURIComponent(params[keys[i]]) + "&";
        }
    }
    sig += tk;

    postString += "_sig=" + md5(sig);

    ajax(
        `//${apiHost}/m.api`,
        "POST",
        postString,
        function(xhr) {
            xhr.withCredentials = true;
        },
        function(text, xhr) {
            var data = JSON.parse(text);
            success(
                {
                    success: true,
                    data: data.content[0],
                    stat: data.stat,
                },
                xhr,
            );
        },
        error,
    );
}

const IS_WX = /MicroMessenger/.test(navigator.userAgent);
if (IS_WX && false) {
    const wxAppId = "wxf2fce8882ecba27c";

    gateway(
        {
            _mt: "krait.sign",
            appId: wxAppId,
            url: location.href,
        },
        res => {
            console.log(res);
            if (res.success) {
                window.wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: wxAppId, // 必填，公众号的唯一标识
                    timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                    nonceStr: res.data.noncestr, // 必填，生成签名的随机串
                    signature: res.data.signature, // 必填，签名，见附录1
                    jsApiList: [
                        "updateAppMessageShareData",
                        "updateTimelineShareData",
                    ], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                    // openTagList: setupOptions.openTagList, // 可选，添加开放标签，如："wx-open-launch-weapp"
                });
                window.wx.ready(() => {
                    const shareData = {
                        title: "平安健康官方网站",
                        desc: "省时、省心、又省钱",
                        link: location.href,
                        img: `${location.protocol}//${location.host}/assets2/images/biglogo.png`,
                    };
                    // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
                    // 需在用户可能点击分享按钮前就先调用
                    window.wx.updateAppMessageShareData({
                        title: shareData.title, // 分享标题
                        desc: shareData.desc, // 分享描述
                        link: shareData.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: shareData.img, // 分享图标
                        success: function() {
                            // 设置成功
                            console.log("updateAppMessageShareData success!");
                        },
                    });

                    // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
                    // 需在用户可能点击分享按钮前就先调用
                    window.wx.updateTimelineShareData({
                        title: shareData.title, // 分享标题
                        link: shareData.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: shareData.img, // 分享图标
                        success: function() {
                            // 设置成功
                            console.log("updateTimelineShareData success!");
                        },
                    });
                });
            }
        },
    );
}
